import React from "react";

import "./Home.css"
import { Container } from "react-bootstrap";
function About(props)
{
    return <>
        <section id="hero">
            <h1>Welcome to SyncIt</h1>
            <p>Your premier destination for cutting-edge IoT solutions revolutionizing everyday life.</p>
        </section>
        <Container>
            <p style={{fontSize:"20px",textAlign:"justify"}}>
                At SyncIt, we're committed to harnessing the power of Artificial Intelligence and Internet of Things technology to tackle the fundamental challenges faced in various domains. From safeguarding agricultural fields with our FieldWatch devices to ensuring kitchen safety with KitchenWatch, and even protecting against rooftop intrusions with RoofWatch, we provide a diverse range of innovative devices designed to simplify your daily routines and enhance peace of mind. Our mission is simple: to leverage the latest advancements in AI and IoT to address common problems effectively, empowering individuals and businesses alike to navigate their environments with confidence and ease.
            </p>
            
            <p style={{fontSize:"20px",textAlign:"justify",marginTop:"20px"}}>
            SyncIt, a specialized branch of Innoverse Tech, leads the frontier of AI and IoT solutions, specializing in monitoring and managing IoT devices with precision and efficiency. Innoverse Tech, as a whole, stands as a pioneering force in AI and IoT, Robotics and Automation, Machine Design and Control, Software Development, and cutting-edge research, driving technological advancement and excellence across various industries.
            </p>
        </Container>
    </>
}

export default About;