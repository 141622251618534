import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import CarouselImage from './CarouselImage';

import "./InfoCarousel.css"

function InfoCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    


    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item interval={5000}>
        <CarouselImage text="FieldWatch"/>
      
        <Carousel.Caption>
          <h3 className="carouselHeading">Animals Affecting Crops? FieldWatch has you covered!</h3>
          <p className="carouselPara">Defend your agriculture field from threats and animals with SyncIt's FieldWatch</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={5000}>
     
      <CarouselImage text="RoofWatch"/>
        <Carousel.Caption>
          <h3 className="carouselHeading">Monkeys causing havoc? RoofWatch has you covered!</h3>
          <p className="carouselPara">Keeping Monkeys at Bay, Safeguarding Your Day with SyncIt's RoofWatch.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={5000}>
     
      <CarouselImage text="KitchenWatch"/>
        <Carousel.Caption>
          <h3 className="carouselHeading">Worried about Kitchen Mishaps? KitchenWatch has you covered!</h3>
          <p className="carouselPara">
            A Watchful Eye for a Safer Culinary Experience. With SyncIt's KitchenWatch.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

  );
}

export default InfoCarousel;