import React from "react";
import "./Home.css"
import { CardGroup} from "react-bootstrap";
import InfoCarousel from "../components/InfoCarousel";
import Cows from "../img/Cows.png"
import Monkeys from "../img/Monkeys.png"
import Kitchen from "../img/Kitchen.png"
import ProductCard from "../components/ProductCard";
import ContactUs from "../components/ContactUs";

const Products = [
    {
        title:"FieldWatch",
        info:"With real-time monitoring utilizing advanced AI and IoT technology, FieldWatch ensures the protection of your field crops from potential threats posed by animals, such as cows. It safeguards your crops, ensuring they remain secure and undisturbed, thus preserving their yield and quality",//"Defend your agriculture field from threats and animals. FieldWatch offers advanced monitoring and deterrent solutions to protect your crops and livestock.",
        img:Cows
    },
    {
        title:"RoofWatch",
        info: "Utilizing advanced AI and IoT technology, RoofWatch provides vigilant surveillance and protection for your belongings. With real-time monitoring and intelligent alerts, it ensures that your home remains elevated and secure, shielding your valuable possessions from potential mischief and damage caused by monkeys.",//"Keeping Your Home Elevated and Protected. RoofWatch utilizes IoT technology to safeguard your home from threats such as animals or environmental factors. With real-time monitoring and alerts, RoofWatch ensures the safety and integrity of your property.",
        img:Monkeys
    },
    {
        title:"KitchenWatch",
        info:"KitchenWatch ensures comprehensive monitoring of your kitchen in your absence, utilizing advanced AI and IoT technology to offer real-time surveillance and intelligent alerts, safeguarding your kitchen environment and appliances from potential hazards and mishaps.",//"A Watchful Eye for a Safer Culinary Experience. KitchenWatch provides real-time monitoring of your kitchen appliances and environment, ensuring safety and efficiency in your culinary endeavors.",
        img:Kitchen
    }
]

function Home(props)
{
    return <>

    <section>
        <InfoCarousel/>
    </section>
    <section>
        <h2 className="section-title">Our AI & IoT Enabled Solutions</h2> {/* Example title */}
        <CardGroup>
            {Products.map((product)=>{return <ProductCard title={product.title} img={product.img} info={product.info} />})}
        </CardGroup>
    </section>

    <ContactUs/>




    </>
}

export default Home;