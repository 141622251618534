import React from "react"
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from "../logo.svg"
function Navigation(props)
{
    return (

        
        <Navbar expand="lg"  data-bs-theme="light" bg="warning"  style={{backgroundColor:"#20B2AA",color:"white"}}>
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            Innoverse Tech | SyncIt
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end"> 
                <Nav className="ml-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/about">About</Nav.Link>
                    {/* <NavDropdown title="Products" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">FieldWatch</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                            KitchenWatch
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">RoofWatch</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                            Separated link
                        </NavDropdown.Item>
                    </NavDropdown> */}
                </Nav>
            </Navbar.Collapse>
        </Container>
      </Navbar>

    )
}

export default Navigation;