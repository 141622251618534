import React from "react"
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function ContactUs()
{
    return <>
 {/* Contact Section */}
 <section className="contact-section">
        <Container>
          <Row>
            <Col md={6}>
            <div className="company-info">
                <h4>Get In Touch</h4>
                <p>SyncIt, a specialized branch of Innoverse Tech, leads the frontier of AI and IoT solutions, specializing in monitoring and managing IoT devices with precision and efficiency. Innoverse Tech, as a whole, stands as a pioneering force in AI and IoT, Robotics and Automation, Machine Design and Control, Software Development, and cutting-edge research, driving technological advancement and excellence across various industries.</p>
                <h4>Contact Information</h4>
                <p><strong>Address:</strong> Daharpur Kalan, Badaun U.P. 243635 India </p>
                <p><strong>Email:</strong> hello@innoverse.org.in</p>
                <p><strong>Phone:</strong> +91 8218106606</p>
                <p><i>Thanks for Visiting Us. We are developing this website and soon all the features will be available. Till then you may email us or just dial up our number.</i></p>
              </div>
            </Col>
            <Col md={6}>
            <div className="contact-form">
                <h2 className="text-center mb-4">Contact Us</h2>
                <Form>
                  <Form.Group controlId="formName" style={{margin:"10px"}}>
                    <Form.Control type="text" placeholder="Your Name" />
                  </Form.Group>
                  <Form.Group controlId="formEmail" style={{margin:"10px"}}>
                    <Form.Control type="email" placeholder="Your Email" />
                  </Form.Group>
                  <Form.Group controlId="formMessage" style={{margin:"10px"}}>
                    <Form.Select aria-label="Default select example">
                      <option>Product You are Interested In</option>
                      <option value="1">FieldWatch</option>
                      <option value="2">RoofWatch</option>
                      <option value="3">KitchenWatch</option>
                      <option value="4">Want Some Other Product</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="formMessage" style={{margin:"10px"}}>
                    <Form.Control as="textarea" rows={5} placeholder="Your Message" />
                  </Form.Group>
                 
                  <Form.Group controlId="formMessage" style={{margin:"10px"}}>
                    <Button variant="primary" type="submit" block>
                        Send Message
                    </Button>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    </>
}

export default ContactUs;