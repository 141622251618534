
import React from "react"
import {Button, Card} from "react-bootstrap";
import "../utilities/Styles.min.css"
function ProductCard(props)
{
    return <>
        <Card className="productCard">
        <Card.Img variant="top" src={props.img} className="productCard-img-top"/>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>
            {props.info}
          </Card.Text>
           
        </Card.Body>
        
        <div className="card-footer d-md-flex justify-content-md-between"> {/* Utilizing flexbox and justify-content-between for medium and larger screens */}
            <div className="d-flex w-100 mb-2 mb-md-0">
                <Button className="btn btn-primary flex-fill" style={{margin:"5px"}}>View More</Button> {/* "View More" button */}
            </div>
            <div className="d-flex w-100 mb-2 mb-md-0">
                <Button className="btn btn-warning flex-fill" style={{margin:"5px"}}>Buy It</Button> {/* "Buy It" button */}
            </div>
        </div>
      </Card>
    
    </>
}

export default ProductCard;