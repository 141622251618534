import React from "react"

function Footer(props)
{
    return (
        <>       
          <footer class="footer">
         
            </footer>
        </>
    )
}

export default Footer;