import React from "react";
import carasouelbg from "../img/carouselbg.avif"
import "./InfoCarousel.css"
function CarouselImage(props)
{
    return <>
    <img
          className="d-block w-100 CarouselImage"
          src={carasouelbg}
          
          alt={props.text}
        />
    </>
}

export default CarouselImage;